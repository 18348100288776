import Vue from 'vue'
import $ from 'jquery'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'
import Swal from 'sweetalert2'
import imgError from '@/assets/error.png'

Vue.use(Vuex)

const Toast = Swal.mixin({
   toast: true,
   position: 'top-end',
   showConfirmButton: false,
   timer: 3500,
   timerProgressBar: true,
   didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
   }
})

const store = {
   state: {
      // urlRest: 'http://localhost:8080/ecommerce-1.0.0/',
      urlRest: 'https://redepaulista.com.br:8440/Carolina-Ecommerce/',
      listaMarcas: [],
      listaCategorias: [],
      listaUnidadeMedida: [],
      listaProdutos: {'segunda': [], 'terca': [], 'quarta': [], 'quinta': [], 'fimSemana': []},
      isRouteAdmin: false,
      isCarregando: false,
      logado: {'cliente': false, 'admin': false, 'token': ''},
      dadosUsuario: {'cliente': {}, 'admin': {}},
      carrinho: {'itens': [], 'valorTotal': 0, 'quantidadeTotal': 0, 'toggle': false},
      pesquisa: {'resultado': [], 'categoria': null, 'subcategoria': null, 'marca': null, 'listaSubcategorias': []},
      countPedidosPendentes: 0
   },
   mutations: {
      isRouteAdmin : (state, isAdmin) => {
         state.isRouteAdmin = isAdmin
      },
      /* eslint-disable-next-line no-unused-vars */
      imageError : (state, e) => {
         e.target.src = imgError
      },
      login : (state, credenciais) => {
         if (localStorage.usernameCliente != undefined && localStorage.senhaCliente != undefined) {
            credenciais = {
               "usuario": localStorage.usernameCliente.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join(""),
               "senha": localStorage.senhaCliente.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
            }
         }

         if (credenciais == undefined) {
            return
         } else {
            $("#loading").fadeIn();
         }

         axios({
            method: 'get',
            url: state.urlRest +'user/login',
            params: {
               login: credenciais.usuario,
               password: credenciais.senha
            }
         }).then(function (response) {
            state.dadosUsuario.cliente = response.data
            state.logado.cliente = true

            localStorage.usernameCliente = credenciais.usuario.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
            localStorage.senhaCliente = credenciais.senha.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")

            router.push('/').catch(function(){})

         }).catch(function (error) {
            store.mutations.deslogar(state)

            if (error.response != undefined) {
               if (error.response.status == 401) {
                  Toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else if (error.response.status == 403) {
                  Swal.fire({
                     icon: 'error',
                     title: 'Conta bloqueada!',
                     text: 'Número de tentativas excedido.'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            $("#loading").fadeOut();
         });
      },
      loginAdmin : (state, credenciais) => {
         if (localStorage.username != undefined && localStorage.senha != undefined) {
            credenciais = {
               "usuario": localStorage.username.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join(""),
               "senha": localStorage.senha.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
            };
         }

         if (credenciais == undefined) {
            return
         } else {
            state.isCarregando = true
         }

         axios({
            method: 'get',
            url: state.urlRest +'adminuser/login',
            params: {
               username: credenciais.usuario,
               password: credenciais.senha
            }
         }).then(function (response) {
            state.dadosUsuario.admin = response.data
            state.logado.token = response.data.tokenSessao
            state.logado.admin = true

            if (credenciais.manterConectado != false) {
               localStorage.username = credenciais.usuario.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
               localStorage.senha = credenciais.senha.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("")
            }

            router.push('/admPaginaInicial').catch(function(){})

         }).catch(function (error) {
            store.mutations.deslogarAdmin(state)

            if (error.response != undefined) {
               if (error.response.status == 401) {
                  Toast.fire({
                     icon: 'error',
                     title: 'Credenciais incorretas!'
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      deslogar : state => {
         state.dadosUsuario.cliente = {}
         state.logado.cliente = false
         localStorage.removeItem('usernameCliente')
         localStorage.removeItem('senhaCliente')

         router.push('/login')
      },
      deslogarAdmin : state => {
         state.dadosUsuario.admin = {}
         state.logado.token = ''
         state.logado.admin = false
         localStorage.removeItem('username')
         localStorage.removeItem('senha')

         router.push('/').catch(function(){})
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;
      },
		getData : state => {
         return new Promise(function() {
            $("#loading").fadeIn();

            axios({
               method: 'get',
               url: state.urlRest +'product/getdata'

            }).then(response => {
               state.listaMarcas = response.data.marcas
               state.listaLojas = response.data.lojas
               state.listaCategorias = response.data.categorias
               state.listaUnidadeMedida = response.data.unidadeDeMedida
               state.listaProdutos.segunda = response.data.segunda
               state.listaProdutos.terca = response.data.terca
               state.listaProdutos.quarta = response.data.quarta
               state.listaProdutos.quinta = response.data.quinta
               state.listaProdutos.fimSemana = response.data.fimSemana

            }).catch(function (error) {
               if (error.response != undefined) {
                  Toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               $("#loading").fadeOut();

               $('.product__electronic-3').owlCarousel({
                  loop:true,
                  margin:20,
                  autoplay:false,
                  autoplayTimeout:60000,
                  smartSpeed:500,
                  nav:false,
                  dots:false,
                  responsive:{
                     0:{
                        items:1
                     },
                     576:{
                        items:2
                     },
                     767:{
                        items:3
                     },
                     992:{
                        items:3
                     },
                     1200:{
                        items:2
                     },
                     1600:{
                        items:3
                     }
                  }
               });

               $('select').niceSelect('update');
            });
         })
      },
		pesquisar : (state, json) => {
         $("#loading").fadeIn();

         state.pesquisa.listaSubcategorias = state.listaCategorias.filter(obj => obj.categoria == json.categoria).map(obj => obj.subcategorias)[0]
         state.pesquisa.categoria = json.categoria
         state.pesquisa.subcategoria = json.subcategoria
         state.pesquisa.marca = json.marca

         axios({
            method: 'get',
            url: state.urlRest +'product/search',
            params: {
               categoria: json.categoria,
               nomeProduto: json.valor,
               subcategoria: json.subcategoria,
               marca: json.marca
            }
         }).then(response => {
            state.pesquisa.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               Toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               Toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            $("#loading").fadeOut();
            $(".offcanvas__area").removeClass("opened");
            $(".body-overlay").removeClass("opened");

            router.push('/produtos').catch(function(){})

            setTimeout(() => {
               document.body.scrollTop = 0;
               document.documentElement.scrollTop = 0;
            }, 250);
         });
      },
      addCarrinho : (state, produto) => {
         let carrinho = localStorage.carrinho != undefined ? JSON.parse(localStorage.carrinho) : []
         
         carrinho.unshift(produto)
         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho.itens = carrinho
      },
      removeCarrinho : (state, index) => {
         let carrinho = localStorage.carrinho != undefined ? JSON.parse(localStorage.carrinho) : []

         carrinho.splice(index, 1) 
         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho.itens = carrinho
      },
      alterarQuantidadeCarrinho : (state, json) => {
         let carrinho = localStorage.carrinho != undefined ? JSON.parse(localStorage.carrinho) : []

         carrinho[json.index].quantidade = json.quantidade
         
         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho.itens = carrinho
         state.carrinho.valorTotal = carrinho.map(produto => parseFloat(produto.preco) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor)
      },
      limparCarrinho : state => {
         let carrinho = []

         localStorage.carrinho = JSON.stringify(carrinho)
         state.carrinho = {'itens': [], 'valorTotal': 0, 'quantidadeTotal': 0, 'toggle': false}
      },
      configurarCarrinho : state => {
         try {
            state.carrinho.valorTotal = JSON.parse(localStorage.carrinho).map(produto => parseFloat(produto.preco) * parseFloat(produto.quantidade)).reduce((total, valor) => total += valor)
            state.carrinho.quantidadeTotal = JSON.parse(localStorage.carrinho).length
            state.carrinho.itens = JSON.parse(localStorage.carrinho)

         } catch (e) {
            state.carrinho = {'itens': [], 'valorTotal': 0, 'quantidadeTotal': 0}
         }
         
         if (state.carrinho.itens.length == 0) {
            router.push('/').catch(function(){})
         }
      },
      toggleCarrinho : (state, toggle) => {
         state.carrinho.toggle = toggle

         if (toggle) {
            $(".cart__toggle").addClass("cart__toggle-open");

         } else {
            $(".cart__toggle").removeClass("cart__toggle-open");
            $(".cart__mini").removeClass("cart__opened");
         }

         $(".cart__mini.cart__opened").animate({ scrollTop: $(".cart__mini.cart__opened ul").height() }, 0);
      },
      buscarPedidosPendentes : state => {
         return new Promise(function() {
            axios({
               method: 'get',
               url: state.urlRest +'adminproduct/checkpendingorders',
               headers: {
                  'Authorization': 'Bearer ' + state.logado.token +'@@@'+ state.dadosUsuario.admin.usuarioUsername 
               }
            }).then(response => {
               state.countPedidosPendentes = parseInt(response.data)

            }).catch(function (error) {
               if (error.response != undefined) {
                  if (error.response.status == 408) {
                     store.mutations.deslogarAdmin(state)

                     Toast.fire({
                        icon: 'warning',
                        title: 'Sessão expirada!'
                     });
                  } else {
                     Toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     });
                  }
               } else {
                  Toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            });
         })
      }
   },
   actions: {
      isRouteAdmin : ({commit}, isAdmin) => {
         commit('isRouteAdmin', isAdmin)
      },
      imageError : ({commit}, e) => {
         commit('imageError', e)
      },
      pesquisar : ({commit}, json) => {
         commit('pesquisar', json)
      },
      toggleCarrinho : ({commit}, toggle) => {
         commit('toggleCarrinho', toggle)
      },
      login : ({commit}, credenciais) => {
         commit('login', credenciais)
      },
      loginAdmin : ({commit}, credenciais) => {
         commit('loginAdmin', credenciais)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      addCarrinho : ({commit}, produto) => {
         commit('addCarrinho', produto)
      },
      removeCarrinho : ({commit}, index) => {
         commit('removeCarrinho', index)
      },
      alterarQuantidadeCarrinho : ({commit}, json) => {
         commit('alterarQuantidadeCarrinho', json)
      },
      buscarPedidosPendentes : context => context.commit('buscarPedidosPendentes'),
      limparCarrinho : context => context.commit('limparCarrinho'),
      configurarCarrinho : context => context.commit('configurarCarrinho'),
      deslogarAdmin : context => context.commit('deslogarAdmin'),
      deslogar : context => context.commit('deslogar'),
      getData : context => context.commit('getData')
   },
   modules: {
   }
}

export default new Vuex.Store(store)