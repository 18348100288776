<template>
	<footer>
      <div class="footer__area footer-bg">
         <div class="footer__top pt-40 pb-20">
            <div class="container">
               <div class="row">
                  <div class="col-12">
                     <div class="row align-items-center">
                        <div class="col-xl-3 col-md-4 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <div class="footer__logo">
                                    <a href="javascript:;"><img src="@/assets/cliente/img/logo/logo-white.png" alt=""></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-6 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <div class="footer__widget-title">
                                    <h4>Loja Eucaliptos</h4>
                                 </div>
                              </div>
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li title="Visite-nos">
                                          <a href="https://goo.gl/maps/dPRi2DZVfgCmjhVG9" target="_blank">
                                             <i class="far fa-map-marker-alt me-1 font-12 color-theme-secondary"></i> Av. Venezuela, 1005
                                          </a>
                                       </li>
                                       <li title="Entre em contato">
                                          <a href="tel:4136040102"><i class="far fa-phone-alt me-1 font-12 color-theme-secondary"></i> (41) 3604-0102</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-6 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <h4>Loja Santa Terezinha</h4>
                              </div>
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li title="Visite-nos">
                                          <a href="https://goo.gl/maps/DxXkcedo1J17Kfxj6" target="_blank">
                                             <i class="far fa-map-marker-alt me-1 font-12 color-theme-secondary"></i> R. Jaguariaíva, 1360
                                          </a>
                                       </li>
                                       <li title="Entre em contato">
                                          <a href="tel:4136040102"><i class="far fa-phone-alt me-1 font-12 color-theme-secondary"></i> (41) 3604-0102</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-6 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <h4>Loja Eucaliptos II</h4>
                              </div>
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li title="Visite-nos">
                                          <a href="https://goo.gl/maps/wbjuqEkp6369JWVM6" target="_blank">
                                             <i class="far fa-map-marker-alt me-1 font-12 color-theme-secondary"></i> R. Pessegueiro, 743
                                          </a>
                                       </li>
                                       <li title="Entre em contato">
                                          <a href="tel:4136040102"><i class="far fa-phone-alt me-1 font-12 color-theme-secondary"></i> (41) 3604-0102</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-6 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <h4>Loja Iguaçu</h4>
                              </div>
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li title="Visite-nos">
                                          <a href="https://goo.gl/maps/4cdXA5cV2Mt4RQeA7" target="_blank">
                                             <i class="far fa-map-marker-alt me-1 font-12 color-theme-secondary"></i> Av. Rio Piquiri, 288
                                          </a>
                                       </li>
                                       <li title="Entre em contato">
                                          <a href="tel:4136040102"><i class="far fa-phone-alt me-1 font-12 color-theme-secondary"></i> (41) 3604-0102</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-xl-3 col-md-4 col-12 text-center">
                           <div class="footer__widget">
                              <div class="footer__widget-title">
                                 <h4>Loja Nações</h4>
                              </div>
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li title="Visite-nos">
                                          <a href="https://goo.gl/maps/JhUmd8znt28h7uNV7" target="_blank">
                                             <i class="far fa-map-marker-alt me-1 font-12 color-theme-secondary"></i> Av. Paraguai, 1877
                                          </a>
                                       </li>
                                       <li title="Entre em contato">
                                          <a href="tel:4136040102"><i class="far fa-phone-alt me-1 font-12 color-theme-secondary"></i> (41) 3604-0102</a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6 col-xl-3 text-center">
                           <div class="footer__widget mb-0">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" href="https://www.instagram.com/superpaulistafrg/" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-instagram font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">Instagram</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div class="col-6 col-xl-3 text-center">
                           <div class="footer__widget mb-0">
                              <div class="footer__widget-content">
                                 <div class="footer__link">
                                    <ul>
                                       <li class="footer__widget-title">
                                          <a class="p-0" href="https://www.facebook.com/supermercadopaulistafrg" target="_blank" title="Visite nossa página">
                                             <i class="fab fa-facebook-square font-20 color-theme-secondary"></i>
                                             <h4 class="pt-1">Facebook</h4>
                                          </a>
                                       </li>
                                    </ul>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="footer__bottom pt-60 pb-60">
            <div class="container">
               <div class="row">
                  <div class="col-xl-12">
                     <div class="footer__links text-center">
                        <p>
                           <marca v-for="(marca, index) in listaMarcas" :key="index" :marca="marca" />
                        </p>
                     </div>
                     <div class="footer__download text-center">
                        <h4>Nossas marcas</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="footer__copyright pt-30 pb-30 footer-bottom-bg">
            <div class="container">
               <div class="row align-items-center">
                  <div class="col-12">
                     <div class="footer__copyright-text">
                        <p class="text-center">Copyright © <a href="https://agenciaoneway.com/" target="_blank">OneWay Systems.</a> Todos os direitos reservados.</p>
                     </div>
                  </div>
                  <div class="col-12 mt-3">
                     <div class="footer__copyright-text">
                        <p class="text-center font-10">
                           Os preços e condições são válidos exclusivamente para compras pela internet e durante o dia de hoje, sujeitos a disponibilidade de estoque e alteração de preço sem aviso prévio. 
                           <br>Imagens dos produtos são meramente ilustrativas.
                           <br>Venda sujeita à disponibilidade de estoque no dia da entrega.
                           <br>No caso de faltar algum produto, este não será entregue e o valor correspondente não será cobrado.
                           <br>Em caso de produto vendido por peso, o valor/gramagem do produto pode sutilmente variar.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>

import { mapState } from 'vuex'
import marca from '@/components/produtos/Marca.vue'

export default {
	name: 'Footer',
   computed: {
		... mapState({
         listaMarcas: state => state.listaMarcas
		})
	},
   components: {
      marca
	}
}

</script>