<template>
   <div class="sidebar-wrapper" data-simplebar="true">
      <div class="sidebar-header">
         <div>
            <img src="@/assets/admin/images/logo-icon.png" class="logo-icon" alt="logo icon">
         </div>
         <div>
            <img src="@/assets/admin/images/logo-text.png" class="logo-img-text" alt="logo text">
         </div>
         <div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i></div>
      </div>

      <ul class="metismenu pb-5" id="menu">
         <li class="my-2" :class="$route.path == '/admPaginaInicial' ? 'mm-active' : ''">
            <router-link to="/admPaginaInicial" class="px-2">
               <div class="parent-icon"><i class="far fa-home"></i></div>
               <div class="menu-title">Página inicial</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admProdutos' ? 'mm-active' : ''" v-if="dadosUsuario.admin.usuarioPermissoes.includes('Ver/Editar produtos')">
            <router-link to="/admProdutos" class="px-2">
               <div class="parent-icon"><i class="far fa-apple-alt"></i></div>
               <div class="menu-title">Produtos</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admPedidos' ? 'mm-active' : ''" v-if="dadosUsuario.admin.usuarioPermissoes.includes('Ver/Editar pedidos')">
            <router-link to="/admPedidos" class="px-2">
               <span class="icon-warning bg-transparent color-theme" title="Pedidos pendentes" v-if="countPedidosPendentes > 0">{{ countPedidosPendentes }}</span>
               <div class="parent-icon"><i class="far fa-clipboard"></i></div>
               <div class="menu-title">Pedidos</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admContagem' ? 'mm-active' : ''" v-if="dadosUsuario.admin.usuarioPermissoes.includes('Atualizar Estoque')">
            <router-link to="/admContagem" class="px-2">
               <div class="parent-icon"><i class="far fa-box"></i></div>
               <div class="menu-title">Contagem</div>
            </router-link>
         </li>
         <li class="my-0" :class="$route.path == '/admVendas' ? 'mm-active' : ''" v-if="dadosUsuario.admin.usuarioPermissoes.includes('Ver Vendas')">
            <router-link to="/admVendas" class="px-2">
               <div class="parent-icon"><i class="far fa-shopping-cart"></i></div>
               <div class="menu-title">Vendas</div>
            </router-link>
         </li>
      </ul>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'

export default {
	name: 'MenuLateral',
   computed: {
      ... mapState({
         countPedidosPendentes: state => state.countPedidosPendentes,
         dadosUsuario: state => state.dadosUsuario
      })
   },
   mounted() {
      $(".mobile-toggle-menu").on("click", function() {
         $(".wrapper").addClass("toggled")
      })

      $(".toggle-icon").click(function() {
         $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
            $(".wrapper").addClass("sidebar-hovered")
         }, function() {
            $(".wrapper").removeClass("sidebar-hovered")
         }))
      })

      $('.page-content').scroll(function() {
         if ($('.page-content').scrollTop() > 300) {
            $('.back-to-top').fadeIn();
         } else {
            $('.back-to-top').fadeOut();
         }
      });

      $('.back-to-top').on('click', function(e) {
         e.preventDefault();
         $('.page-content').animate({scrollTop:0}, '300');
      });

      $(".switcher-btn").on("click", function() {
         $(".switcher-wrapper").addClass("switcher-toggled")
      })

      $(".close-switcher").on("click", function() {
         $(".switcher-wrapper").removeClass("switcher-toggled")
      })

      $("#lightmode").on("click", function() {
         $("html").attr("class", "light-theme")
         localStorage.theme = "light-theme";
      })

      $("#primary2mode").on("click", function() {
         $("html").attr("class", "primary2-theme")
         localStorage.theme = "primary2-theme";
      })

      $("#warning2mode").on("click", function() {
         $("html").attr("class", "warning2-theme")
         localStorage.theme = "warning2-theme";
      })

      $("#success2mode").on("click", function() {
         $("html").attr("class", "success2-theme")
         localStorage.theme = "success2-theme";
      })

      $("#dangermode").on("click", function() {
         $("html").attr("class", "danger-theme")
         localStorage.theme = "danger-theme";
      })

      $("#primarymode").on("click", function() {
         $("html").attr("class", "primary-theme")
         localStorage.theme = "primary-theme";
      })

      $("#darkmode").on("click", function() {
         $("html").attr("class", "dark-theme")
         localStorage.theme = "dark-theme";
      })

      $("#successmode").on("click", function() {
         $("html").attr("class", "success-theme")
         localStorage.theme = "success-theme";
      })
   }
}

</script>