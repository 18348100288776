import Vue from 'vue'
import $ from 'jquery'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   // Cliente
   {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
   },
   {
      path: '/finalizar',
      name: 'Finalizar',
      component: () => import('../views/Finalizar.vue')
   },
   {
      path: '/login',
      name: 'Login',
      component: () => import('../views/Login.vue')
   },
   {
      path: '/registro',
      name: 'Registro',
      component: () => import('../views/Registro.vue')
   },
   {
      path: '/perfil',
      name: 'Perfil',
      component: () => import('../views/Perfil.vue')
   },
   {
      path: '/produtos',
      name: 'Produtos',
      component: () => import('../views/Produtos.vue')
   },
   // Admin
   {
      path: '/admPaginaInicial',
      name: 'AdmPágina_Inicial',
      component: () => import('../views/admin/PaginaInicial.vue')
   },
   {
      path: '/admProdutos',
      name: 'AdmProdutos',
      component: () => import('../views/admin/Produtos.vue')
   },
   {
      path: '/admPedidos',
      name: 'AdmPedidos',
      component: () => import('../views/admin/Pedidos.vue')
   },
   {
      path: '/admContagem',
      name: 'AdmContagem',
      component: () => import('../views/admin/Contagem.vue')
   },
   {
      path: '/admVendas',
      name: 'AdmVendas',
      component: () => import('../views/admin/Vendas.vue')
   }
]

const router = new VueRouter({
   mode: 'abstract',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   store.dispatch('isRouteAdmin', String(to.path).startsWith('/adm'))

   $(".offcanvas__area").removeClass("opened");
   $(".body-overlay").removeClass("opened");

   next()
})

export default router
