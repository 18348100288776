
<template>
   <div class="cart__item d-flex justify-content-between align-items-center">
      <div class="cart__inner d-flex flex-grow-1">
         <div class="cart__thumb d-none d-sm-inline">
            <a href="javascript:;">
               <img class="bg-white" :src="produto.foto == null ? '' : produto.foto" alt="product" @error="imageError">
            </a>
         </div>
         <div class="cart__details flex-grow-1">
            <h6 class="regulaLinha"><a href="javascript:;"> {{ produto.nome }}</a></h6>
            <div class="cart__price">
               <span class="d-block d-sm-inline" v-if="page == 'PERFIL'">
                  R$ {{ (parseFloat(produto.preco) / parseFloat(produto.quantidade)).toFixed(2) }} ({{ produto.quantidade }}x R$ {{ parseFloat(produto.preco).toFixed(2) }})
               </span>
               <span class="d-block d-sm-inline" v-else>
                  R$ {{ parseFloat(produto.preco).toFixed(2) }} ({{ produto.quantidade }}x R$ {{ (parseFloat(produto.preco) * parseFloat(produto.quantidade)).toFixed(2) }})
               </span>
               <span class="float-sm-end text-dark font-16 pe-5 no-select" v-if="page != 'CARRINHO' && produto.semEstoque != true">
                  <i class="fas fa-minus me-3 cursor-pointer color-theme font-13" @click="alterarQuantidade('MINUS')" v-if="parseFloat(produto.quantidade) > 0.25 && page == 'FINALIZAR'"></i>
                  {{ produto.quantidade }}
                  <i class="fas fa-plus ms-3 cursor-pointer color-theme font-13" @click="alterarQuantidade('PLUS')" v-if="page == 'FINALIZAR'"></i>
               </span>
            </div>
         </div>
      </div>
      <div class="cart__del text-center" v-if="page == 'PERFIL' && produto.semEstoque == true">
         <i class="far fa-box text-danger font-16"></i><br>Sem estoque
      </div>
      <div class="cart__del" v-if="page != 'PERFIL'">
         <a href="javascript:;" @click="removeCarrinho" title="Remover"><i class="far fa-trash"></i></a>
      </div>
   </div>
</template>

<script>

export default {
	name: 'Carrinho',
   props: ['produto', 'index', 'page'],
   data: function() {
		return {
         urlAtual: window.location.hostname
		}
	},
   methods: {
      removeCarrinho: function () {
         this.$store.dispatch('removeCarrinho', this.index)

         this.$toast.fire({
            icon: 'success',
            title: 'Removido do carrinho!'
         });

         this.$store.dispatch('configurarCarrinho')
      },
      alterarQuantidade : function (tipo) {
         if (tipo == 'MINUS') {
            if (this.produto.unidadeDeMedida == 'KG') {
               this.produto.quantidade = (parseFloat(this.produto.quantidade) - 0.25).toFixed(3)

            } else {
               this.produto.quantidade = String(parseInt(this.produto.quantidade) - 1)
            }
         } else {
            if (this.produto.unidadeDeMedida == 'KG') {
               this.produto.quantidade = (parseFloat(this.produto.quantidade) + 0.25).toFixed(3)
   
            } else {
               this.produto.quantidade = String(parseInt(this.produto.quantidade) + 1)
            }
         }
         
         this.$store.dispatch('alterarQuantidadeCarrinho', {'index': this.index, 'quantidade': this.produto.quantidade})
      },
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      }
   }
}

</script>