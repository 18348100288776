
<template>
   <a href="javascript:;" :title="'Buscar por '+ marca.nome" @click="pesquisar">
      <span class="text-capitalize"> {{ marca.nome.toLowerCase() }}</span>
   </a>
</template>

<script>

import { mapState } from 'vuex'

export default {
	name: 'Marca',
   props: ['marca'],
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa
		})
	},
   methods: {
      pesquisar : function () {
         this.$store.dispatch('pesquisar', {'valor': '', 'categoria': this.pesquisa.categoria, 'subcategoria': this.pesquisa.subcategoria, 'marca': this.marca.nome})
      }
   }
}

</script>