<template>
	<div id="app" class="admin" v-if="isRouteAdmin">
		<div class="body-loading" v-if="isCarregando == true">
			<div class="clock-loader"></div>
		</div>

		<div class="wrapper" v-if="logado.admin">
			<!-- Menu Lateral -->
			<Lateral />

			<!-- Menu NavBar -->
			<NavBar />
			
			<!-- Corpo sistema -->
			<div class="page-wrapper">
				<div class="page-content pb-5">
					<h5 class="mb-3">{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/").replace("Adm", "") : '' }}</h5>
					
					<router-view />
				</div>

				<a href="javaScript:;" class="back-to-top bg-theme">
					<i class='fas fa-arrow-up'></i>
				</a>
			</div>

			<div class="overlay toggle-icon"></div>
			
			<footer class="page-footer">
				<span class="ps-2 float-start d-none d-md-block">Copyright © 2024. Todos direitos reservados</span>
				<span class="pe-2 float-end">OneWay Systems</span>
			</footer>
		</div>

		<Login v-else />
   </div>

	<div id="app" class="cliente" v-else>
		<div id="loading" v-if="isCarregando == true">
			<div id="loading-center">
				<div id="loading-center-absolute">
					<div id="object"></div>
				</div>
			</div>
		</div>

		<div class="progress-wrap">
			<svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
				<path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
			</svg>
		</div>
		
		<Header />

		<div class="offcanvas__area">
			<div class="offcanvas__wrapper">
			<div class="offcanvas__close">
				<button class="offcanvas__close-btn mt-sm-2" id="offcanvas__close-btn">
					<i class="fal fa-times"></i>
				</button>
			</div>
			<div class="offcanvas__content">
				<div class="offcanvas__logo mb-15">
					<a href="javascript:;">
						<img src="@/assets/cliente/img/logo/logo-black.png" alt="logo">
					</a>

					<div class="row m-0 d-flex d-sm-none">
						<div class="col-6 p-0 mt-4 text-center">
							<router-link class="weight-600 text-center" to="/perfil" v-if="logado.cliente"><i class="far fa-user"></i><br>Minha conta</router-link>
							<router-link class="weight-600 text-center" to="/login" v-else><i class="far fa-user"></i><br>Minha conta</router-link>
						</div>
						<div class="col-6 p-0 mt-4 text-center">
							<a href="javascript:;" class="weight-600 text-center" @click="setRouteAdmin"><i class="far fa-lock"></i><br>Área admin.</a>
						</div>
					</div>
				</div>
				<div class="offcanvas__search mb-25">
					<form action="javascript:;">
						<input type="text" placeholder="Pesquisar..." v-model="pesquisaVal.valor">
						<button @click="pesquisar(pesquisa.categoria)"><i class="far fa-search"></i></button>
					</form>
				</div>
				<div class="mobile-menu fix mean-container">
					<div class="mean-bar">
						<nav class="mean-nav">
							<ul class="overflow">
								<li>
									<a href="javascript:;" class="hover" :class="pesquisa.categoria == 'Todas categorias' ? 'bg-selected' : ''" title="Buscar por Todas categorias" @click="pesquisar('Todas categorias')">
										Todas categorias
									</a>
								</li>
								<li v-for="(categoria, index) in listaCategorias" :key="index">
									<a href="javascript:;" class="hover" :class="pesquisa.categoria == categoria.categoria ? 'bg-selected' : ''" :title="'Buscar por '+ categoria.categoria" @click="pesquisar(categoria.categoria)">
										{{ categoria.categoria }}
									</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div class="offcanvas__action"></div>
			</div>
			</div>
		</div>

		<div class="body-overlay"></div>

		<router-view />

		<div class="btn-whatsapp hover" title="Entre em contato">
			<a class="d-flex align-items-center" href="https://wa.me/5541992876227" target="_blank">
				<div class="header__hotline-icon me-0 me-md-3">
					<i class="fab fa-whatsapp text-secondary"></i>
				</div>
				<div class="header__hotline-info d-none d-md-inline">
					<span>Entre em contato:</span>
					<h6>(41) 99287-6227</h6>
				</div>
			</a>
		</div>

		<Footer />
   </div>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import Login from '@/views/admin/Login.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/admin/MenuNavBar.vue'
import Lateral from '@/components/admin/MenuLateral.vue'

export default {
	name: 'App',
	data: function() {
		return {
         pesquisaVal: {'valor': '', 'categoria': 'Todas categorias', 'subcategoria': 'Todas subcategorias', 'marca': null},
		}
	},
	computed: {
		... mapState({
			listaCategorias: state => state.listaCategorias,
         isCarregando: state => state.isCarregando,
         isRouteAdmin: state => state.isRouteAdmin,
			pesquisa: state => state.pesquisa,
         logado: state => state.logado
		})
	},
	components: {
		Footer, Header, Login, Lateral, NavBar
	},
	methods: {
		pesquisar : function (categoria) {
			if (categoria != null) {
				this.pesquisaVal.categoria = categoria
			}

			this.$store.dispatch('pesquisar', this.pesquisaVal)
      },
		setRouteAdmin : function () {
			$(".offcanvas__area").removeClass("opened");
			$(".body-overlay").removeClass("opened");

			this.$store.dispatch('isRouteAdmin', true)
      }
	},
	mounted() {
		this.$store.dispatch('getData')
		this.$store.dispatch('login')

		router.push('/').catch(function(){})

		Vue.nextTick(function() {
			$(".body-overlay").on("click", function () {
				$(".header__search").removeClass("search-opened");
				$(".body-overlay").removeClass("opened");
			});

			$(".offcanvas__close-btn").on("click", function () {
				$(".offcanvas__area").removeClass("opened");
				$(".body-overlay").removeClass("opened");
			});

			$(".body-overlay").on("click", function () {
				$(".offcanvas__area").removeClass("opened");
				$(".body-overlay").removeClass("opened");
			});
		}.bind(this));
	}
}

</script>